module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Florian Blum', // Navigation and Site Title
  siteTitleAlt: 'Florian Blum', // Alternative Site title for SEO
  siteTitleShort: 'Florian Blum', // short_name for manifest
  siteTitleSuffix: ' | Florian Blum', // short_name for manifest
  siteHeadline: 'Personal website of Florian Blum', // Headline for schema.org JSONLD
  siteUrl: 'https://florianblum.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo-flo.jpg', // Used for SEO and manifest
  siteDescription:
    'Florian Blum - Blockchain and Software Engineering Researcher, Fallout nerd and passionate gardener',
  author: 'Florian Blum', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@colddevil', // Twitter Username
  ogSiteName: 'Florian Blum', // Facebook Site Name
  ogLanguage: 'en_US', // og:language
  //googleAnalyticsID: 'UA-XXXXXX-X',

  // Manifest and Progress color
  themeColor: '#158fdb',
  backgroundColor: '#333',
}
