import React from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { readableColor, transparentize } from 'polished'
import 'typeface-work-sans'
import { AnimatedBox, Box, Button, Flex } from '../elements'
import theme from '../../config/theme'
import reset from '../styles/reset'
import FloPhoto from '../images/photo-flo.jpg'

const GlobalStyles = createGlobalStyle`
  *::before,
  *::after {
    box-sizing: border-box;
  }
  ::selection {
    color: white;
    background-color: #f6993f;
  }
  html {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    
    h1, h2, h3, h4, h5, h6 {
      font-weight: ${theme.fontWeights.bold};
    }
    
    h1 {
      font-size: ${theme.fontSizes[5]};
    }
    h2 {
      font-size: ${theme.fontSizes[4]};
    }
    h3 {
      font-size: ${theme.fontSizes[3]};
    }
    h4 {
      font-size: ${theme.fontSizes[2]};
    }
    h5 {
      font-size: ${theme.fontSizes[1]};
    }
    h6 {
      font-size: ${theme.fontSizes[0]};
    }
    
    @media (max-width: 600px) {
      font-size: 16px;
      
      h1 {
        font-size: ${theme.fontSizes[4]};
      }
      h2 {
        font-size: ${theme.fontSizes[3]};
      }
      h3 {
        font-size: ${theme.fontSizes[2]};
      }
      h4 {
        font-size: ${theme.fontSizes[1]};
      }
      h5 {
        font-size: ${theme.fontSizes[0]};
      }
      h6 {
        font-size: ${theme.fontSizes[0]};
      }
    }
  }
  body {
    border: 0;
    margin: 0;
    padding: 0;
    color: black;
    font-family: 'Work Sans', '-apple-system', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background: white;
    font-size: 18px;
  }
  a {
    transition: all 0.3s ease-in-out;
    color: black;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: ${theme.colors.primary};
    }
  }
  
  .socialIcon {
    color: #ff0000;
  }
  
  ${reset}
`

const isPartiallyActive = ({ isPartiallyCurrent }: { isPartiallyCurrent: boolean }) =>
  isPartiallyCurrent ? { className: 'navlink-active navlink' } : { className: 'navlink' }

const PartialNavLink = ({ children, ...rest }: { children: React.ReactNode }) => (
  <Link getProps={isPartiallyActive} {...rest}>
    {children}
  </Link>
)

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.theme.sidebarWidth.big} 1fr;
  @media (max-width: ${props => props.theme.breakpoints[4]}) {
    grid-template-columns: ${props => props.theme.sidebarWidth.normal} 1fr;
  }

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
  }
`

const SideBarInner = styled(Box)<{ bg: string }>`
  position: relative;
  height: 100%;
  width: ${props => props.theme.sidebarWidth.big};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;

  background: ${props => props.bg};

  @media (max-width: ${props => props.theme.breakpoints[4]}) {
    width: ${props => props.theme.sidebarWidth.normal};
  }

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    position: relative;
    width: 100%;
  }

  svg {
    fill: ${props => readableColor(`${props.bg}`)};
  }

  .imageCover {
    width: 100%;
    border-radius: 50%;

    object-fit: cover;
    object-position: center right;
  }

  .nameDetail {
    color: ${props => readableColor(`${props.bg}`)};
    font-style: italic;
    font-size: 0.8rem;
    text-align: center;
  }

  .nameFull {
    color: ${props => readableColor(`${props.bg}`)};
    font-size: 1.8rem;
  }

  .nameDescr {
    color: ${props => readableColor(`${props.bg}`)};
    text-align: center;
  }
`

const Nav = styled(Flex)<{ color: string }>`
  a {
    text-decoration: none;
    color: ${props => transparentize(0.5, readableColor(`${props.color}`))};
    font-size: ${props => props.theme.fontSizes[3]};
    line-height: 1.5;
    &:hover,
    &:focus,
    &.navlink-active {
      color: ${props => props.theme.colors.primary};
    }

    @media (max-width: ${props => props.theme.breakpoints[2]}) {
      font-size: ${props => props.theme.fontSizes[2]};
      margin-left: ${props => props.theme.space[4]};
    }

    @media (max-width: ${props => props.theme.breakpoints[1]}) {
      font-size: ${props => props.theme.fontSizes[1]};
      margin-left: ${props => props.theme.space[3]};
    }

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      font-size: ${props => props.theme.fontSizes[0]};
      margin-left: ${props => props.theme.space[2]};
    }
  }
`

const Main = styled.main`
  @media (min-width: calc(${props => props.theme.breakpoints[2]} + 1px)) {
    grid-column-start: 2;
  }
`

const Footer = styled.footer<{ color: string }>`
  background: ${props => props.color};

  color: ${props => readableColor(`${props.color}`, `${props.theme.colors.grey}`, '#c3c3c3')};

  .legalLink {
    font-size: 0.8rem;
    color: ${props => transparentize(0.5, readableColor(`${props.color}`))};
    text-align: center;
  }

  a {
    color: ${props => readableColor(`${props.color}`)};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    position: relative;
    width: 100%;
  }
`

const cfg = require('../../config')

type LayoutProps = { children: React.ReactNode } & typeof defaultProps

const defaultProps = {
  color: cfg.backgroundColor,
}

interface QueryResult {
  navigation: {
    edges: {
      node: {
        name: string
        link: string
      }
    }[]
  }
}

const Layout = ({ children, color }: LayoutProps) => {
  const data: QueryResult = useStaticQuery(query)

  const IconEmpty = ({ url, col = '#FFFFFF', width = 30, height = 30, fill = 'none', pathDescriptions }) => (
    <a href={url}>
      <svg className="socialIcon" width={width} height={height} viewBox="0 0 24 24">
        <g stroke={col} fill={fill} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
          {pathDescriptions.map(pathDescription => (
            <path key={pathDescription} d={pathDescription} />
          ))}
        </g>
      </svg>
    </a>
  )

  const IconTwitter = ({ url }) => (
    <IconEmpty
      url={url}
      pathDescriptions={[
        'M23.18 6.71l-2.1-.9 1.1-2.4-2.56.85a4.47 4.47 0 00-3-1.2 4.51 4.51 0 00-4.5 4.5v1c-3.54.73-6.63-1.2-9.5-4.5-.5 2.667 0 4.667 1.5 6l-3.29-.5a4.55 4.55 0 004.25 4l-2.75 1c1 2 2.82 2.31 5.25 2.5a11.517 11.517 0 01-6.75 2c12.76 5.67 20.25-2.66 20.25-10v-.83l2.1-1.52z',
      ]}
    />
  )

  const IconGithub = ({ url }) => (
    <IconEmpty
      url={url}
      pathDescriptions={[
        'M14.41 22.35a.5.5 0 00.64.48c5.969-1.723 9.412-7.959 7.689-13.929-1.723-5.969-7.96-9.412-13.929-7.688S-.602 9.172 1.121 15.142a11.25 11.25 0 007.689 7.689.5.5 0 00.64-.48V20A2.81 2.81 0 016 18.28 6.074 6.074 0 004.64 16c2.85.69 2.9 2.54 4.84 1.67a4.004 4.004 0 01.63-1.82c-2.2-.25-4.52-.6-4.52-4.4a3.841 3.841 0 011-2.66 3.563 3.563 0 01.1-2.62s.83-.27 2.73 1a9.388 9.388 0 015 0c1.89-1.28 2.72-1 2.72-1 .365.829.401 1.766.1 2.62a3.832 3.832 0 011 2.66c0 3.81-2.32 4.15-4.53 4.39.473.682.712 1.5.68 2.33l.02 4.18z',
      ]}
    />
  )

  const IconGoogle = ({ url }) => (
    <IconEmpty
      url={url}
      pathDescriptions={[
        'M18.1 14.7h-4.63v-4.17h9.78v2.06c-.34 6.215-5.654 10.978-11.869 10.638C5.166 22.888.403 17.574.743 11.359S6.397.381 12.612.721A11.268 11.268 0 0120.25 4.3l-3.4 3.13A6.61 6.61 0 0012 5.33a6.67 6.67 0 106.1 9.37zM6.18 8.71L2.59 5.84M6.19 15.32l-3.44 3.1M15.92 17.39l3.07 3.45',
      ]}
    />
  )

  const IconInstagram = ({ url }) => (
    <IconEmpty
      url={url}
      pathDescriptions={[
        'M6.74.75h10.5a6 6 0 016 6v10.5a6 6 0 01-6 6H6.74a6 6 0 01-6-6V6.75a6 6 0 016-6z',
        'M11.99 7a5 5 0 11-.001 10.001A5 5 0 0111.99 7zM19.34 5.15a.5.5 0 01-1 0M18.34 5.15a.5.5 0 011 0',
      ]}
    />
  )

  const IconLinkedin = ({ url }) => (
    <IconEmpty
      url={url}
      pathDescriptions={[
        'M5.67 22.63H.81V8.7h4.86v13.93zM15.76 13.24a2.14 2.14 0 00-2.14 2.14h0v7.25H8.51V8.7h5.11v1.59a6.758 6.758 0 014.28-1.6c3.17 0 5.37 2.35 5.37 6.81v7.13H17.9v-7.25a2.14 2.14 0 00-2.13-2.15h-.01v.01zM5.76 3.88a2.5 2.5 0 11-2.5-2.5 2.5 2.5 0 012.48 2.5h.02z',
      ]}
    />
  )

  const IconXing = ({ url }) => (
    <IconEmpty
      url={url}
      pathDescriptions={[
        'M6.58 4.66H1.73L4.65 10l-3.9 6.4h4.86l3.9-6.4-2.93-5.34zM23.25.75h-5.16l-7.81 13.68 5.04 8.82h5.15l-5.03-8.82L23.25.75z',
      ]}
    />
  )

  const IconDblp = ({ url }) => (
    <IconEmpty
      url={url}
      pathDescriptions={[
        'M4.2 9.7c0-.4.2-.6.5-.7.3-.2.6-.4 1-.5.3-.1.4-.3.3-.6-.3-1.2-.6-2.3-.9-3.5l-.6-2.1c0-.1-.1-.2-.1-.3 0-.3.1-.4.3-.3.3.1.5.2.7.4 1.7 1 3.3 2 5 3 .7.4 1.2.9 1.3 1.8.1.3.2.7.3 1.1.1.3.2.3.5.2.6-.3 1.2-.6 1.8-.8.7-.4 1.5-.7 2.3-1.1 0 0 .1 0 .1-.1.7-.3.9-.3 1.1.5.4 1.6.9 3.3 1.3 4.9.2.7.4 1.4.6 2.2.2.8.2 1-.6 1.3-.3.1-.5.3-.8.4-.4.2-.4.2-.3.6.3 1.3.7 2.5 1 3.8.2.6.3 1.2.5 1.9v.3c0 .2-.1.3-.3.2-.2-.1-.4-.2-.5-.3-1.9-1.1-3.7-2.2-5.6-3.4-.4-.3-.7-.6-.8-1.1-.1-.5-.2-.9-.4-1.4-.2-.5-.2-.5-.7-.3-1.4.7-2.8 1.3-4.3 2-.5.2-.7.1-.9-.4-.6-2.4-1.3-4.8-1.9-7.1.2-.2.1-.4.1-.6z',
      ]}
    />
  )

  const IconRg = ({ url }) => (
    <IconEmpty
      url={url}
      /*fill={'#ffffff'}*/
      pathDescriptions={[
        'M9.2 6.5c1 0 2 .3 2.9.9 1.8 1.2 1.9 3.4 1.3 5-.5 1.3-1.5 2.1-2.7 2.7-.6.3-.6.3-.3.8 1 1.8 2.2 3.5 3.6 5 .6.6 1.2 1.1 2 1.4.2.1.3.3.4.4-.2.1-.3.3-.5.4-1.3.1-2.6-.1-3.5-1.1-.6-.6-1.2-1.3-1.7-2.1-.8-1.2-1.6-2.4-2.3-3.7-.3-.5-.7-.8-1.3-.8-1.3 0-1.3-.1-1.3 1.3v3.6c.2 1.4.4 1.5 1.5 1.7.2 0 .4.1.7.2.1 0 .2.2.2.2 0 .1-.1.2-.2.3-.2.1-.3.1-.5.1H2.7c-.3 0-.7.1-.7-.3-.1-.5.4-.4.7-.5.8-.1 1.2-.6 1.3-1.4v-1.2-9.7c0-.4 0-.9-.1-1.4-.1-.6-.4-.9-1-1-.2 0-.5-.1-.7-.2-.1 0-.2-.2-.2-.3 0-.1.2-.2.3-.3.2-.1.4 0 .6 0 1 0 5.2-.1 6.3 0zm-3.3 4.4v2.7c0 .6.1.7.7.7.5 0 1.1.1 1.6 0 1.7-.2 2.9-.9 3.4-2.8.5-2.1-.8-3.9-2.9-4.1H6.6c-.6.1-.7.2-.7.8-.1.9 0 1.8 0 2.7zM15.8 5.4V3.8c.1-1.2.6-2.1 1.7-2.6 1.3-.5 2.4-.3 3.5.5.1.1.3.2.4.4.1.2.4.4.1.7-.3.3-.7.3-1-.1-.5-.7-1.2-1-2.1-.7-.8.3-1.3 1-1.4 1.9 0 1.1-.1 2.2 0 3.4.1 1.5 1.8 1.9 2.7 1.5.7-.3 1.2-1.4 1-2.2-.1-.3-.2-.4-.5-.4h-.8c-.3 0-.4-.2-.4-.4 0-.3.1-.4.4-.4h2c.3 0 .4.1.5.4 0 1 .1 1.9-.4 2.8-.2.5-.6.8-1 1.1-1.7.9-4.4.5-4.8-2.4 0-.7.1-1.3.1-1.9z',
      ]}
    />
  )

  const IconDeviant = ({ url }) => (
    <IconEmpty
      url={url}
      pathDescriptions={[
        'M4.9 5.35l5.9 10.48h0l-9.36 2.82C.56 16.49.15 10.16 8.82 8 18.54 5.49 23 12 23 12l-9 2.77L11.21 10',
      ]}
    />
  )

  const IconThreeBox = ({ url }) => (
    <IconEmpty
      url={url}
      pathDescriptions={[
        'M3.48 0h17A3.48 3.48 0 0124 3.48v17a3.48 3.48 0 01-3.48 3.48h-17A3.48 3.48 0 010 20.44v-17A3.48 3.48 0 013.48 0zm8.64 4a6.63 6.63 0 00-2 .29 5.16 5.16 0 00-1.64.87 5 5 0 00-1.17 1.41 6.07 6.07 0 00-.64 2l1.44.25h.37a1 1 0 00.61-.18 1.17 1.17 0 00.37-.6 2.64 2.64 0 01.89-1.32A2.52 2.52 0 0112 6.26a2.34 2.34 0 011.69.58 2.2 2.2 0 01.62 1.71 3.05 3.05 0 01-.15 1 1.67 1.67 0 01-.55.75 2.7 2.7 0 01-1.05.49 7 7 0 01-1.67.19v1.95a8.4 8.4 0 011.94.18 2.87 2.87 0 011.11.52 1.6 1.6 0 01.51.8 4.32 4.32 0 01.12 1.05 2.61 2.61 0 01-.17.9 2.49 2.49 0 01-.52.81 2.73 2.73 0 01-.87.59 3.05 3.05 0 01-1.22.23 2.83 2.83 0 01-1.06-.18 2.56 2.56 0 01-.78-.48 3.07 3.07 0 01-.57-.67c-.16-.25-.3-.5-.44-.76a.82.82 0 00-.34-.35 1.14 1.14 0 00-.6-.14 1.58 1.58 0 00-.63.13l-1.2.5A9.14 9.14 0 007 17.87a5 5 0 001.13 1.32 4.47 4.47 0 001.56.81 6.89 6.89 0 002.1.28 6.87 6.87 0 002.09-.28 5.24 5.24 0 001.74-1 4.43 4.43 0 001.19-1.54 4.71 4.71 0 00.45-2.11 3.76 3.76 0 00-.71-2.35 4.17 4.17 0 00-2.14-1.38 4.71 4.71 0 001.06-.5 2.94 2.94 0 00.8-.73 3.17 3.17 0 00.49-1 4.53 4.53 0 00.18-1.34 4.07 4.07 0 00-.34-1.63 3.86 3.86 0 00-1-1.3 4.37 4.37 0 00-1.52-.88A5.9 5.9 0 0012.12 4z',
      ]}
    />
  )

  const socialLinks = [
    <IconTwitter url="https://twitter.com/colddevil" />,
    <IconGithub url="https://github.com/ColdDevil" />,
    <IconInstagram url="https://www.instagram.com/c0lddevil" />,
    <IconThreeBox url="https://www.3box.io/0xfac0475b677b54f72682E0EA633Ffa1088110dcf/" />,
    <IconLinkedin url="https://www.linkedin.com/in/florianblum/" />,
    <IconXing url="https://www.xing.com/profile/Florian_Blum22/" />,
    // <IconDeviant url="https://www.deviantart.com/colddevil" />,
    <IconDblp url="https://dblp.uni-trier.de/pers/hd/b/Blum:Florian" />,
    <IconRg url="https://www.researchgate.net/profile/Florian_Blum" />,
    <IconGoogle url="https://scholar.google.de/citations?user=zsjUTu0AAAAJ" />,
  ]

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Wrapper>
          <SideBarInner bg={color} as="aside" p={[6, 6, 6, 8]}>
            <Box width={['5rem', '5rem', '5rem', '9rem']}>
              <Link to="/" aria-label="Go back to home page">
                <img className="imageCover" src={FloPhoto} alt="Photo of Florian Blum" />
              </Link>
            </Box>
            <Box pt="2" className="nameFull">
              Florian Blum
            </Box>
            <Box className="nameDetail">(Previously Florian Wessling)</Box>
            <Box pt="5" className="nameDescr" width={['75%', '50%', '50%', '100%']}>
              Blockchain and Software Engineering Researcher, Fallout nerd and passionate gardener 😊
            </Box>
            <Nav
              color={color}
              mt={[5, 5, 5, 8]}
              as="nav"
              flexWrap="nowrap"
              flexDirection={['row', 'row', 'row', 'column']}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              {data.navigation.edges.map(({ node: item }) => (
                <PartialNavLink to={item.link} key={item.name}>
                  {item.name}
                </PartialNavLink>
              ))}
            </Nav>
            <Flex
              flexWrap="wrap"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              pt={[2, 2, 2, 7]}
              p={[2, 2, 2]}
            >
              {socialLinks.map(item => (
                <Box p={5} key={item.props.url}>
                  {item}
                </Box>
              ))}
            </Flex>
            <Footer color={color}>
              <MyPBox px={6} style={{ textAlign: 'center' }}>
                <Link className="legalLink" to="/legal" aria-label="Visit legal notice page">
                  Impressum / Legal Notice
                </Link>
              </MyPBox>
            </Footer>
          </SideBarInner>
          <Main>{children}</Main>
        </Wrapper>
      </>
    </ThemeProvider>
  )
}

const PBox = styled(AnimatedBox)`
  max-width: 1400px;
  margin: 0 auto;
`
export const MyPBox = ({ children, ...rest }: { children: React.ReactNode }) => (
  <PBox pt={[1]} pb={[5]} px={[6, 6, 8, 10]} {...rest}>
    {children}
  </PBox>
)

export default Layout

Layout.defaultProps = defaultProps

const query = graphql`
  query LayoutQuery {
    navigation: allNavigationYaml {
      edges {
        node {
          name
          link
        }
      }
    }
  }
`
